.container {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 100vh;
	background-color: #141E30;
	background-image: radial-gradient(circle, #86d3ac, #72c1ad, #5eae93, #4a9d88, #358b75);
}

.authBlock {
	max-width: 680px;
	top: 0;
	position: sticky;
	margin-bottom: 25px !important;
}

.authContainer {
	margin: 25px;
}

.stack {
	margin: 25px 45px;
}

.title {
	z-index: 0;
	text-transform: Uppercase;
	margin-bottom: .5em;
	font-family: 'Roboto', sans-serif;
	font-size: 3rem;
	color: #e4e6e6;
	position: relative;
	background-image: radial-gradient(circle, #cef0f8, #bff0f2, #b1eaec, #a3d9e6, #95d7e0);
	background-size: auto auto;
	background-clip: border-box;
	background-size: 200% auto;
	background-clip: text;
	text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: textclip 10s linear infinite;
}


.title:before,
.title:after {
	content: attr(data-text);
	position: absolute;
	top: 0;
	left: 0;
}

.title:before {
	z-index: -1;
	text-shadow: -0.001em -0.001em 1px rgba(255, 255, 255, 0.63);
}

.title:after {
	z-index: -2;
	text-shadow: 1px 2px 2px rgba(0, 0, 0, .1);
	mix-blend-mode: multiply;
}

.title2 {
	margin-top: -1.15em;
	font-family: 'Roboto', sans-serif;
	font-size: 1.6rem;
	color: #F7971E;
	letter-spacing: .1em;
	text-align: center;
	opacity: 0.8;
	text-shadow: 0px -1px 1px rgba(255, 255, 255, 0.33), 1px 1px 1px rgba(13, 0, 100, 0.9);
}

.subtitle {
	margin: 15px 0 0 7px !important;
	font-size: 1rem !important;
	color: #358b75 !important;
}

@keyframes textclip {
	to {
		background-position: 200% center;
	}
}

@media screen and (max-width: 900px) {
	.title {
		font-size: 2.8rem;
	}
	.title2 {
		font-size: 1.5rem;
	}
}

@media screen and (max-width: 500px) {
	.title {
		font-size: 1.8rem;
	}
	.title2 {
		font-size: 1.1rem;
	}
}